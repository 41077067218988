import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const PourquoiSignature = () => (
  <Container>
    <HomeSectionTitle tag='h2'>
      Obtenir le financement et signer chez le notaire
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Maintenant que le local est trouvé et que votre offre a été acceptée, il faut obtenir le financement global de votre acquisition en libérant votre apport personnel et en obtenant si nécessaire un crédit bancaire.        </HomeSectionSubtitle>
    <HomeSectionDescription>
      Grâce à votre évaluation financière en amont ce travail est facilité et en tant que professionnel libéral, il est fortement conseillé de se faire accompagner par un cabinet ou des spécialistes de ce type de profession. Ils vous permettront de négocier les bonnes modalités pour votre emprunt bancaire, adapté à votre situation personnelle et professionnelle.
      Pour confirmer vos critères de recherche, regardez en détail les annonces de locaux professionnels à vendre.
    </HomeSectionDescription>
  </Container>
)

export default PourquoiSignature
