import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'

import media from '../../../utils/media'
import TopSection from '../../molecules/TopSection'
import LaunchButton from '../../atoms/LaunchButton'
import BackgroundImage from '../../molecules/BackgroundImage'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledBackground = styled(BackgroundImage)`
  z-index: -1;
  display: none;

  ${media.greaterThan('xl')`
    display: block;
    height: 700px;
    top: 245px;
  `}
`
const Container = styled.div`
  width: 100%;
  max-width: 600px;

  ${media.lessThan('md')`
    width:90%;
  `}
`
const StyledContainer = styled(Container)`
  max-width: 650px;
  margin: auto;
  height: 700px;
  padding-top: 140px;
  
  ${media.greaterThan('md')`
    padding-top: 140px;
  `}
  
  ${media.greaterThan('xl')`
    max-width: 500px;
    margin-right: 55%;
    padding-top: 140px;
  `}

  ${media.greaterThan('xxl')`
    max-width: 580px;
    padding-top: 140px;
    margin-right: 50%;
  `}
`

const Title = styled(HomeSectionTitle)`
  color: ${({ theme }) => get(theme, 'white', '#fff')};
`
const Subtitle = styled(HomeSectionSubtitle)`
  color: ${({ theme }) => get(theme, 'white', '#fff')};
`
const Description = styled(HomeSectionDescription)`
  color: ${({ theme }) => get(theme, 'white', '#fff')};
`
const StyledButton = styled(LaunchButton)`
  width: fit-content;
  margin-top: 24px;

  ${media.lessThan('lg')`
    width: 100%;
  `}
`

const CommentPourquoiSearch = () => {
  const data = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "sell/top-section.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <TopSection component={() =>
      <StyledBackground
        alt='Comment et pourquoi se lancer dans un achat de local professionnel'
        data={data} />}>
      {/* <TopSectionContent
        title='Acheter un local Professionnel Comment et pourquoi se lancer ?'
        bullets={BULLETS}
        Button={Button} /> */}
      <StyledContainer>
        <Title tag='h1'>
          Acheter un local Professionnel Comment et pourquoi se lancer ?
        </Title>
        <Subtitle>
          Vous avez décidé de vous lancer dans l&apos;aventure d’acheter votre local professionnel ?
        </Subtitle>
        <Description>
          Félicitations ! C&apos;est une étape passionnante et structurante pour votre carrière.<br />
          Cela nécessite une bonne dose de préparation et un accompagnement dédié pour que cela soit une réussite.<br />
          Chez Trouver Mon Local Pro, nous accompagnons tous les jours des professionnels dans leurs acquisitions.<br />
          Nous allons donc vous donner des conseils pratiques sur la manière de procéder et vous expliquer pourquoi l&apos;achat d&apos;un local professionnel peut être une excellente décision personnelle et professionnelle.
        </Description>
        <StyledButton
          link='/nos-annonces/acheter/?action=acheter'
          title="Acheter un local professionnel"
          background='brightOrange' />
      </StyledContainer>

    </TopSection>
  )
}

export default CommentPourquoiSearch
