import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const PourquoiNegocier = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "purchase/pourquoi-negocier.webp"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
        )
      }
    }
  }`)

  return (
    <ImageSection
      alt='Négocier le prix et les modalités d’achat'
      fixed={false}
      imageData={placeholderImage}
      backgroundColor='white'>
      <HomeSectionTitle tag='h2'>
        Négocier le prix et les modalités d’achat
      </HomeSectionTitle>
      <HomeSectionDescription start='true'>
        Vous avez enfin trouvé un local qui vous convient mais arrive maintenant la discussion du prix de vente.<br/>
        Il n’y a pas de formule magique pour cette étape, mais il est sûr que pour l’acquisition d’un local professionnel, il est essentiel d’essayer d’acheter au bon prix.<br/>
        Pour cela il faut se renseigner au maximum sur les prix du marché, les rentabilités locatives et négocier le prix au regard de ces éléments.<br/>
        C’est notre travail et nous sommes là pour vous aider à discuter du prix, mais également du délai d’achat, de la reprise ou non du matériel, de la remise en état du bien.<br/>
        Vous ne serez pas seuls dans cette phase assez stressante lorsque l’on approche du but.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default PourquoiNegocier
