import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import LaunchButton from '../../atoms/LaunchButton'
import ImageSection from '../../molecules/ImageSection'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const PourquoiFinancement = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "pourquoi/financement.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      alt='Evaluer votre capacité de financement et votre budget pour trouver le local professionnel idéal'
      fixed={false}
      right={true}
      maxText={580}
      imageData={placeholderImage}>
      <HomeSectionTitle tag='h2'>
        Evaluer votre capacité de financement et votre budget
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Comme pour tout achat immobilier, le budget est le critère clé à bien évaluer en amont de votre recherche.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Il se compose de votre apport et du montant que vous pourrez emprunter pour cette acquisition. Pour cela, la banque fera une analyse précise de votre activité, de vos revenus et de votre projet d’achat. Afin de mettre toutes les chances de votre côté, il est essentiel de s’appuyer sur un professionnel pour monter votre dossier financier et sécuriser le financement de votre acquisition.
      </HomeSectionDescription>
      <ButtonContainer>
        <LaunchButton
          link='/nos-annonces/demande-accompagnement'
          title='Prendre rendez-vous avec un conseiller financier'
          background='brightOrange' />
      </ButtonContainer>
    </ImageSection>
  )
}

export default PourquoiFinancement
