import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import {
  isEmpty, includes, isEqual, reduce, join, isArray,
  map, get, isNumber, pickBy
} from 'lodash'

import Modal from '../../atoms/Modal'
import AlertForm from '../../molecules/AlertForm'
import SubmitButton from '../../atoms/SubmitButton'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import { useCreateAlert } from '../../../queries/alerts'
import { useFetchMeQuery } from '../../../queries/users'

const ErrorContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin: 0 10%;
`
const ErrorButton = styled(SubmitButton)`
  width: 300px;
  align-self: center;
`
const Message = styled(HomeSectionSubtitle)`
margin-bottom: 24px;
`

const ModalAlert = ({ openAlert, handleOpenAlert, values }) => {
  const { mutateAsync } = useCreateAlert()

  const locationParams = reduce(get(values, 'location'), (acc, val, key) => {
    if (isEmpty(val)) {
      return acc
    }

    const queryParamsForVal = map(val, iter => `${key}=${get(iter, 'id')}`)

    return `${acc}${queryParamsForVal.join('&')}&`
  }, '')

  const hiddenParams = ['location', 'locationInput', 'rooms']
  const params = pickBy(values, (val, key) => (isNumber(val) || !isEmpty(val)) && !includes(hiddenParams, key))

  const queryParams = join(map(params, (val, key) => {
    if (isEqual(key, 'professions') && isEqual(val, 'Toutes professions')) {
      return ''
    }

    if (isArray(val)) {
      return join(map(val, iter => `${key}[]=${iter}`), '&')
    }
    return `${key}=${val}`
  }), '&')

  if (typeof window !== 'undefined') {
    const currentParams = new URLSearchParams()

    const multiValueParams = ['city', 'region', 'department', 'agglomeration']

    const newParams = new URLSearchParams(`${locationParams}${queryParams}`)
    for (const [key, value] of newParams.entries()) {
      if (multiValueParams.includes(key)) {
        currentParams.append(key, value)
      } else {
        currentParams.set(key, value)
      }
    }

    currentParams.set('page', '1')
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`
    localStorage.setItem('currentPath', newUrl)
  }

  const { error } = useFetchMeQuery(false)

  if (!isEmpty(error)) {
    return (
      <Modal
        size='500px'
        top='60'
        isOpen={openAlert}
        onRequestClose={handleOpenAlert}>
        <ErrorContainer>
          <Message>
            Connectez vous pour créer et gérer vos alertes
          </Message>
          <ErrorButton
            color='brightOrange'
            onClick={() => {
              navigate('/profile/login')
            }}>
            Se connecter
          </ErrorButton>
          <ErrorButton
            color='blue'
            onClick={() => {
              navigate('/profile/signin')
            }}>S'inscrire
          </ErrorButton>
        </ErrorContainer>
      </Modal>
    )
  }

  return (
    <Modal
      size='80%'
      top='40%'
      isOpen={openAlert}
      onRequestClose={handleOpenAlert}>
      <AlertForm
        search={values}
        callback={() => { navigate('/profile/') }}
        buttonLabel='Créer une alerte'
        mutateAsync={mutateAsync}
        handleOpenAlert={handleOpenAlert} />
    </Modal>
  )
}

export default ModalAlert