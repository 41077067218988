import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const PourquoiAccompagnement = () => (
  <Container>
    <HomeSectionTitle tag='h2'>
      Faites vous accompagner par des spécialistes des locaux professionnels
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      L’achat d’un local est une décision majeure qui peut avoir un impact significatif sur votre carrière.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
    Cependant ce projet est souvent très fastidieux, demande du temps alors que vous êtes très pris professionnellement et requiert également des connaissances précises du marché immobilier et du financement des professionnels libéraux.
    <br/><br/>
    C’est pour cela que chez Trouver Mon Local Pro nous sommes complètement dédiés aux locaux professionnels. Grâce à notre expérience de ce marché très spécifique, nous pouvons vous accompagner au mieux pour trouver des solutions adaptées à vos besoins, négocier, et vous guider tout au long du processus d’achat.
    </HomeSectionDescription>
    <ButtonContainer>
      <LaunchButton
        link='/nos-annonces/demande-accompagnement'
        title='Échangez avec un agent Trouver Mon Local Pro'
        background='brightOrange' />
    </ButtonContainer>
  </Container>
)

export default PourquoiAccompagnement
