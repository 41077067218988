import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const PourquoiDefinitionBesoin = () => (
  <Container>
    <HomeSectionTitle tag='h2'>
      Définir vos besoins pour votre local idéal
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Avant de vous lancer dans la recherche d&apos;un local professionnel, il est essentiel de définir clairement vos besoins pour parvenir ensuite à trouver le local idéal.    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Recherchez-vous dans un quartier en particulier ou tout une ville ?
      Quelle est la surface idéale du local pour votre activité ? De combien de pièces avez-vous besoin ? Est-ce qu’une salle d’attente ou une salle de pause sont nécessaires ? Quels sont les équipements et les aménagements dont vous avez besoin ?<br />
      C’est en répondant très tôt à toutes ces questions que vous pourrez être par la suite plus efficace dans votre recherche de biens.
      Pour confirmer vos critères de recherche, regardez en détail les annonces de locaux professionnels à vendre.
    </HomeSectionDescription>
  </Container>
)

export default PourquoiDefinitionBesoin
