import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../../components/atoms/Seo'
import Footer from '../../components/organisms/Footer'
import Navbar from '../../components/organisms/Navbar'
import SiteSection from '../../components/atoms/SiteSection'
import PourquoiSearch from '../../components/organisms/PourquoiSearch'
import PourquoiAcheter from '../../components/organisms/PourquoiAcheter'
import PourquoiPlusieurs from '../../components/organisms/PourquoiPlusieurs'
import PourquoiFinancement from '../../components/organisms/PourquoiFinancement'
import PourquoiAccompagnement from '../../components/organisms/PourquoiAccompagnement'
import PourquoiDefinitionBesoin from '../../components/organisms/PourquoiDefinitionBesoins'
import PourquoiNegocier from '../../components/organisms/PourquoiNegocier'
import PourquoiInstallation from '../../components/organisms/PourquoiInstallation'
import PourquoiSignature from '../../components/organisms/PourquoiSignature'

const TITLE = 'Comment et Pourquoi se lancer ? Trouver Mon Local Pro'
const DESCRIPTION = 'Comment et Pourquoi se lancer dans votre futur local professionnel'

const CommentPourquoi = ({ location }) => {
  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION} />
      <Navbar location={location} />
      <PourquoiSearch />
      <SiteSection id='pourquoi-acheter-plutot-que-louer' margin='100px 0 0 0'>
        <PourquoiAcheter />
      </SiteSection>
      <SiteSection id='acheter-seul-ou-plusieurs' margin='120px 0 0 0'>
        <PourquoiPlusieurs />
      </SiteSection>
      <SiteSection id='definir-vos-besoins-pour-local-ideal' margin='120px 0 0 0'>
        <PourquoiDefinitionBesoin />
      </SiteSection>
      <SiteSection id='capacité-financement-budget' margin='120px 0 0 0'>
        <PourquoiFinancement />
      </SiteSection>
      <SiteSection id='accompagnement-specialistes' margin='120px 0 0 0'>
        <PourquoiAccompagnement />
      </SiteSection>
      <SiteSection id='negocier-prix-modalites' margin='120px 0 0 0'>
        <PourquoiNegocier />
      </SiteSection>
      <SiteSection id='obtenir-financement-et-signature' margin='120px 0 0 0'>
        <PourquoiSignature />
      </SiteSection>
      <SiteSection id='installation' margin='120px 0 80px 0'>
        <PourquoiInstallation />
      </SiteSection>
      <Footer />
    </>
  )
}

CommentPourquoi.propTypes = {
  location: PropTypes.object.isRequired
}

export default CommentPourquoi
