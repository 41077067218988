import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import LaunchButton from '../../atoms/LaunchButton'
import ImageSection from '../../molecules/ImageSection'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const PourquoiInstallation = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "purchase/pourquoi-installation.webp"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
        )
      }
    }
  }`)

  return (
    <ImageSection
      alt='Installation et début de votre activité'
      fixed={false}
      right={true}
      maxText={580}
      imageData={placeholderImage}>
      <HomeSectionTitle tag='h2'>
        Installation et début de votre activité
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Ça y est ! Vous avez enfin récupéré les clés de vos nouveaux locaux.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Il vous faudra peut-être effectuer des travaux et/ou aménager les locaux afin de lancer votre activité professionnelle. C’est également le moment de déposer des annonces pour trouver des locataires si vous recherchez des occupants pour une partie de vos locaux.
        Besoin d’un soutien dans la finalisation de votre installation ?<br/>
        Les spécialistes de Trouver Mon Local Pro vous accompagne de A à Z dans vos démarches afin de démarrer sereinement !
      </HomeSectionDescription>
      <ButtonContainer>
        <LaunchButton
          link='/nos-annonces/demande-accompagnement'
          title='Lancez votre projet avec Trouver Mon Local Pro'
          background='brightOrange' />
      </ButtonContainer>
    </ImageSection>
  )
}

export default PourquoiInstallation
