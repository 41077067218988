import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import PresentationBlocs from '../PresentationBlocs'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const RESIDENCIES = [{
  title: 'Achat seul ',
  subtitle: 'pour votre propre activité',
  image: 'pourquoi-1',
  html: 'Vous achetez un local qui sera uniquement dédié à votre activité professionnelle. Vous pourrez de cette façon optimiser votre achat en fonction de vos propres besoins'
}, {
  title: 'Achat seul pour votre activité',
  subtitle: 'et sous-location',
  image: 'pourquoi-2',
  html: 'Ce choix vous permettra d’acheter un bien plus grand, tout en finançant une grande partie grâce aux loyers que vous toucherez de la location d’une partie de vos locaux. Cette solution est souvent intéressante pour les pros qui souhaitent rentabiliser aux maximum leur acquisition'
}, {
  title: 'Achat',
  subtitle: 'à plusieurs professionnels',
  image: 'pourquoi-3',
  html: 'Vous souhaitez vous installer avec des confrères et acheter ensemble les locaux dans lesquels vous pourrez exercer votre activité. Cela vous permettra de faire baisser le coût d’acquisition en répartissant l’investissement immobilier. Vous avez également la possibilité de louer une partie des locaux pour encore plus rentabiliser votre achat'
}]

const HeaderContainer = styled.div`
  margin: 0 120px;

  ${media.lessThan('xxl')`
    margin: 0 100px;
  `}

  ${media.lessThan('xl')`
    margin: 0 64px;
  `}

  ${media.lessThan('sm')`
    margin: 0 32px;
  `}
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: 0 auto;
  ${media.lessThan('md')`
    margin: 0 16px;
  `}
`

const PourquoiPlusieurs = () => (
  <>
    <HeaderContainer>
      <HomeSectionTitle tag='h2'>
        Acheter seul ou à plusieurs ?
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        La tendance actuelle est que les professionnels souhaitent de plus en plus aujourd’hui travailler dans des locaux avec d’autres “pros”.       </HomeSectionSubtitle>
      <HomeSectionDescription>
        Cela permet d’échanger sur les activités de chacun, créer un lien social et ne pas se sentir seul dans son activité. C’est tout à fait légitime et toutes les solutions sont possibles aujourd’hui en fonction de vos souhaits :      </HomeSectionDescription>
    </HeaderContainer>
    <DetailsContainer>
      <PresentationBlocs blocs={RESIDENCIES} />
    </DetailsContainer>
  </>
)

export default PourquoiPlusieurs
