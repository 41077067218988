import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const PourquoiAcheter = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "purchase/pourquoi-acheter.jpeg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      fixed={false}
      imageData={placeholderImage}
      backgroundColor='white'>
      <HomeSectionTitle tag='h2'>
        Pourquoi acheter plutôt que louer ?
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        La première question qui peut se poser est de savoir s&apos;il vaut mieux acheter ou louer un local professionnel.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        L&apos;achat vous offre en premier lieu la possibilité de devenir propriétaire du bien et de capitaliser sur un actif à long terme.<br/>
        Il est souvent bien plus intéressant de rembourser un prêt plutôt que de payer un loyer.<br/>
        En devenant propriétaire, vous pourrez également personnaliser le local selon vos besoins spécifiques, sans être soumis aux restrictions imposées par un bailleur.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default PourquoiAcheter
